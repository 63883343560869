.contact {
  padding-top: 120px;
  margin-top: -30px;
  min-height: 90vh;
  background-color: #030132;
}

h1 {
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
  font-family: "Blinker", sans-serif;
  font-weight: 800;
}

h1:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 3px solid;
}

#title-row {
  margin-top: 10px;
  margin-bottom: 40px;
}

#contact-title {
  color: whitesmoke;
}

#contact-card {
  background-color: transparent;
  border: none;
}

.card {
  display: flex;
}

.card-header {
  position: relative;
  margin: auto;
  margin-bottom: 10px;
  border-bottom: none;
  background-color: transparent;
  color: whitesmoke;
}

#email-address {
  text-align: center;
  padding-bottom: 20px;
}

#email-link {
  color: #e43666;
}

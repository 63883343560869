#portfolio {
  padding-top: 50px;
  padding-bottom: 70px;
  margin-bottom: -50px;
  min-height: 100vh;
  background-color: #81eff6;
}

#portfolio-title {
  margin: 20px 0px 30px;
}

#main-col {
  padding: 0px 50px;
}
.about {
  padding-top: 50px;
  min-height: 110vh;
  /* background-size: cover; */
  background: #edeffb;
}

#about-main {
  margin-top: 100px;
  margin-bottom: 30px;
}

.blueH1 {
  color: #030132;
  font-family: "Blinker", sans-serif;
  font-weight: 800;
}

.bio {
  color: #030132;
  font-size: 24px;
  margin-right: 40px;
  margin-left: 40px;
  font-family: "Roboto", sans-serif;
  text-align: justify;
  margin-bottom: 10px;
}

#headshot {
  border-radius: 15%;
  float: left;
  margin-top: 7px;
  margin-left: 40px;
  padding-right: 15px;
  
}

#info-section {
  margin-bottom: 50px;
}

#skill-row {
  padding-bottom: 30px;
}

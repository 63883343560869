.skill {
  /* width: 100%; */
  /* height: 20rem; */
  /* object-fit: cover; */
  background-color: transparent;
  height: 100px;
  width: 120px;
 
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  
}



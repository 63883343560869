.card-title {
  margin-top: 90px;
}

img {
  cursor: pointer;
}

.modal-90w {
  min-width: 60vw;
  max-height: calc(100% - 80px);
}

.card {
  overflow: hidden;
  transition: 0.3s;
  border: none;
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
}

.card-img-top {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

.modal-body {
  position: relative;
  /* text-align: center; */
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.img-center {
  /* display: block; */
  margin-left: auto;
  margin-right: auto;
  height: 196px;
  width: 300px;
}

.text-center {
  padding-right: 0 !important;
}

#live-link {
  margin-right: auto;
}

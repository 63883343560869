.fa {
  font-size: 36px;
  color:#e43666;
}

.connect {
  font-weight: 800;
}

#sticky-footer {
  background-color:lightslategray;
  border-top: 10px solid#e43666;
  flex-shrink: 0;
  bottom: 0;
  width: 100vw;
  /* margin-top: -80px; */
}
/*centering text and icons in footer*/
.text-center {
  margin-top: 0;
  padding-right: 10px;
}

.list-group {
  display: inherit !important;
}

.list-group a {
  padding-left: 5px;
  padding-right: 5px;
}
